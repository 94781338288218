@input-box-height: 34px;

.@{calendar-prefix-cls}-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  .placeholder();

  &[disabled] {
    cursor: not-allowed;
  }
}

.@{calendar-prefix-cls}-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: @text-color-secondary;
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}

.@{calendar-prefix-cls}-range {
  width: 552px;
  overflow: hidden;

  .@{calendar-prefix-cls}-date-panel {
    &::after {
      display: block;
      clear: both;
      height: 0;
      visibility: hidden;
      content: '.';
    }
  }
  &-part {
    position: relative;
    width: 50%;
  }

  &-left {
    float: left;
    .@{calendar-prefix-cls} {
      &-time-picker-inner {
        border-right: 1px solid @border-color-split;
      }
    }
  }

  &-right {
    float: right;
    .@{calendar-prefix-cls} {
      &-time-picker-inner {
        border-left: 1px solid @border-color-split;
      }
    }
  }

  &-middle {
    position: absolute;
    left: 50%;
    z-index: 1;
    height: @input-box-height;
    margin: 1px 0 0 0;
    padding: 0 200px 0 0;
    color: @text-color-secondary;
    line-height: @input-box-height;
    text-align: center;
    transform: translateX(-50%);
    pointer-events: none;
  }

  &-right .@{calendar-prefix-cls}-date-input-wrap {
    margin-left: -90px;
  }

  &.@{calendar-prefix-cls}-time &-middle {
    padding: 0 10px 0 0;
    transform: translateX(-50%);
  }

  .@{calendar-prefix-cls}-today
    :not(.@{calendar-prefix-cls}-disabled-cell)
    :not(.@{calendar-prefix-cls}-last-month-cell)
    :not(.@{calendar-prefix-cls}-next-month-btn-day) {
    .@{calendar-prefix-cls}-date {
      color: @primary-color;
      background: @primary-2;
      border-color: @primary-color;
    }
  }

  .@{calendar-prefix-cls}-selected-start-date,
  .@{calendar-prefix-cls}-selected-end-date {
    .calendar-selected-cell;
  }

  &.@{calendar-prefix-cls}-time &-right .@{calendar-prefix-cls}-date-input-wrap {
    margin-left: 0;
  }

  .@{calendar-prefix-cls}-input-wrap {
    position: relative;
    height: @input-box-height;
  }

  .@{calendar-prefix-cls}-input,
  .@{calendar-timepicker-prefix-cls}-input {
    .input;
    height: @input-height-sm;
    padding-right: 0;
    padding-left: 0;
    line-height: @input-height-sm;
    border: 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  .@{calendar-timepicker-prefix-cls}-icon {
    display: none;
  }

  &.@{calendar-prefix-cls}-week-number {
    width: 574px;

    .@{calendar-prefix-cls}-range-part {
      width: 286px;
    }
  }

  .@{calendar-prefix-cls}-year-panel,
  .@{calendar-prefix-cls}-month-panel,
  .@{calendar-prefix-cls}-decade-panel {
    top: @input-box-height;
  }
  .@{calendar-prefix-cls}-month-panel .@{calendar-prefix-cls}-year-panel {
    top: 0;
  }
  .@{calendar-prefix-cls}-decade-panel-table,
  .@{calendar-prefix-cls}-year-panel-table,
  .@{calendar-prefix-cls}-month-panel-table {
    height: 208px;
  }

  .@{calendar-prefix-cls}-in-range-cell {
    position: relative;
    border-radius: 0;
    > div {
      position: relative;
      z-index: 1;
    }
    &::before {
      position: absolute;
      top: 4px;
      right: 0;
      bottom: 4px;
      left: 0;
      display: block;
      background: @item-active-bg;
      border: 0;
      border-radius: 0;
      content: '';
    }
  }

  .@{calendar-prefix-cls}-footer-extra {
    float: left;
  }

  // `div` for selector specificity
  div&-quick-selector {
    text-align: left;

    > a {
      margin-right: 8px;
    }
  }

  .@{calendar-prefix-cls},
  .@{calendar-prefix-cls}-month-panel,
  .@{calendar-prefix-cls}-year-panel,
  .@{calendar-prefix-cls}-decade-panel {
    &-header {
      border-bottom: 0;
    }
    &-body {
      border-top: @border-width-base @border-style-base @border-color-split;
    }
  }

  &.@{calendar-prefix-cls}-time {
    .@{calendar-timepicker-prefix-cls} {
      top: 68px;
      z-index: 2; // cover .ant-calendar-range .ant-calendar-in-range-cell > div (z-index: 1)
      width: 100%;
      height: 207px;
      &-panel {
        height: 267px;
        margin-top: -34px;
      }

      &-inner {
        height: 100%;
        padding-top: 40px;
        background: none;
      }

      &-combobox {
        display: inline-block;
        height: 100%;
        background-color: @component-background;
        border-top: @border-width-base @border-style-base @border-color-split;
      }
      &-select {
        height: 100%;
        ul {
          max-height: 100%;
        }
      }
    }
    .@{calendar-prefix-cls}-footer .@{calendar-prefix-cls}-time-picker-btn {
      margin-right: 8px;
    }
    .@{calendar-prefix-cls}-today-btn {
      height: 22px;
      margin: 8px 12px;
      line-height: 22px;
    }
  }

  &-with-ranges.@{calendar-prefix-cls}-time .@{calendar-timepicker-prefix-cls} {
    height: 233px;
  }
}

.@{calendar-prefix-cls}-range.@{calendar-prefix-cls}-show-time-picker {
  .@{calendar-prefix-cls}-body {
    border-top-color: transparent;
  }
}
