/*--*--*--*--*--*--*--*--*--*--*-*--*--*--*--*--*--*--*--*

  * ------- Fonts ------- *

-*--*--*--*--*--*--*--*--*--*--*--*--*--*--*--*--*--*--*--*/
/*
  -- CoStar Brown --
*/

@font-face {
  font-family: 'CoStar Brown';
  src: url('fonts/costar-brown/CoStarBrown-Bold.eot');
  src: url('fonts/costar-brown/CoStarBrown-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/costar-brown/CoStarBrown-Bold.woff2') format('woff2'),
    url('fonts/costar-brown/CoStarBrown-Bold.woff') format('woff'),
    url('fonts/costar-brown/CoStarBrown-Bold.ttf') format('truetype'),
    url('fonts/costar-brown/CoStarBrown-Bold.svg#CoStarBrown-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CoStar Brown';
  src: url('fonts/costar-brown/CoStarBrown-Regular.eot');
  src: url('fonts/costar-brown/CoStarBrown-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/costar-brown/CoStarBrown-Regular.woff2') format('woff2'),
    url('fonts/costar-brown/CoStarBrown-Regular.woff') format('woff'),
    url('fonts/costar-brown/CoStarBrown-Regular.ttf') format('truetype'),
    url('fonts/costar-brown/CoStarBrown-Regular.svg#CoStarBrown-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CoStar Brown';
  src: url('fonts/costar-brown/CoStarBrown-Light.eot');
  src: url('fonts/costar-brown/CoStarBrown-Light.eot?#iefix') format('embedded-opentype'),
    url('fonts/costar-brown/CoStarBrown-Light.woff2') format('woff2'),
    url('fonts/costar-brown/CoStarBrown-Light.woff') format('woff'),
    url('fonts/costar-brown/CoStarBrown-Light.ttf') format('truetype'),
    url('fonts/costar-brown/CoStarBrown-Light.svg#CoStarBrown-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}