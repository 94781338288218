@import './load-fonts.scss';

$font-family: 'CoStar Brown', sans-serif;
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-light: 300;

body {
  background-color: #f9f9f9 !important;
  font-feature-settings: unset;
  font-variant: unset;
  overflow-x: hidden;
  font-family: $font-family;
}

button {
  outline: none;
}

.marketing-blog-container {
  & > * {
    font-family: $font-family;
  }
}
