.steps-vertical() {
  display: block;
  .@{steps-prefix-cls}-item {
    display: block;
    overflow: visible;
    &-icon {
      float: left;
      margin-right: 16px;
    }
    &-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }
    &-title {
      line-height: @steps-icon-size;
    }
    &-description {
      padding-bottom: 12px;
    }
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: @steps-icon-size + 6px 0 6px;
    &::after {
      width: 1px;
      height: 100%;
    }
  }

  > .@{steps-prefix-cls}-item:not(:last-child)
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    display: block;
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-content
    > .@{steps-prefix-cls}-item-title {
    &::after {
      display: none;
    }
  }

  &.@{steps-prefix-cls}-small .@{steps-prefix-cls}-item-container {
    .@{steps-prefix-cls}-item-tail {
      position: absolute;
      top: 0;
      left: 12px;
      padding: @steps-small-icon-size + 6px 0 6px;
    }
    .@{steps-prefix-cls}-item-title {
      line-height: @steps-small-icon-size;
    }
  }
}

.@{steps-prefix-cls}-vertical {
  .steps-vertical;
}

@media (max-width: @screen-xs) {
  .@{steps-prefix-cls}-horizontal.@{steps-prefix-cls}-label-horizontal {
    .steps-vertical;
  }
}
