.@{calendar-timepicker-prefix-cls} {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: @component-background;

  &-panel {
    position: absolute;
    z-index: @zindex-picker;
    width: 100%;
  }

  &-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    font-size: @font-size-base;
    line-height: 1.5;
    text-align: left;
    list-style: none;
    background-color: @component-background;
    background-clip: padding-box;
    outline: none;
  }
  &-combobox {
    width: 100%;
  }

  &-column-1,
  &-column-1 &-select {
    width: 100%;
  }
  &-column-2 &-select {
    width: 50%;
  }
  &-column-3 &-select {
    width: 33.33%;
  }
  &-column-4 &-select {
    width: 25%;
  }

  &-input-wrap {
    display: none;
  }

  &-select {
    position: relative; // Fix chrome weird render bug
    float: left;
    height: 226px;
    overflow: hidden;
    font-size: @font-size-base;
    border-right: @border-width-base @border-style-base @border-color-split;

    &:hover {
      overflow-y: auto;
    }

    &:first-child {
      margin-left: 0;
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }

    ul {
      width: 100%;
      max-height: 206px;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      width: 100%;
      height: 24px;
      margin: 0;
      line-height: 24px;
      text-align: center;
      list-style: none;
      cursor: pointer;
      transition: all .3s;
      user-select: none;

      &:last-child::after {
        display: block;
        height: 202px;
        content: '';
      }

      &:hover {
        background: @item-hover-bg;
      }

      &:focus {
        color: @primary-color;
        font-weight: 600;
        outline: none;
      }
    }

    li&-option-selected {
      font-weight: 600;
      background: @time-picker-selected-bg;
    }

    li&-option-disabled {
      color: @btn-disable-color;
      &:hover {
        background: transparent;
        cursor: not-allowed;
      }
    }
  }
}

.@{calendar-prefix-cls}-time {
  .@{calendar-prefix-cls}-day-select {
    display: inline-block;
    padding: 0 2px;
    color: @heading-color;
    font-weight: 500;
    line-height: 34px;
  }

  .@{calendar-prefix-cls}-footer {
    position: relative;
    height: auto;

    &-btn {
      text-align: right;
    }

    .@{calendar-prefix-cls}-today-btn {
      float: left;
      margin: 0;
    }

    .@{calendar-prefix-cls}-time-picker-btn {
      display: inline-block;
      margin-right: 8px;

      &-disabled {
        color: @disabled-color;
      }
    }
  }
}
