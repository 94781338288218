@import '../../style/mixins/index';

// mixins for grid system
// ------------------------
.make-row(@gutter: @grid-gutter-width) {
  position: relative;
  height: auto;
  margin-right: (@gutter / -2);
  margin-left: (@gutter / -2);
  .clearfix;
}

.make-grid-columns() {
  .col(@index) {
    @item: ~'.@{ant-prefix}-col-@{index}, .@{ant-prefix}-col-xs-@{index}, .@{ant-prefix}-col-sm-@{index}, .@{ant-prefix}-col-md-@{index}, .@{ant-prefix}-col-lg-@{index}';
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) {
    @item: ~'.@{ant-prefix}-col-@{index}, .@{ant-prefix}-col-xs-@{index}, .@{ant-prefix}-col-sm-@{index}, .@{ant-prefix}-col-md-@{index}, .@{ant-prefix}-col-lg-@{index}';
    .col((@index + 1), ~'@{list}, @{item}');
  }
  .col(@index, @list) when (@index > @grid-columns) {
    @{list} {
      position: relative;
      padding-right: (@grid-gutter-width / 2);
      padding-left: (@grid-gutter-width / 2);
    }
  }
  .col(1);
}

.float-grid-columns(@class) {
  .col(@index) {
    // initial
    @item: ~'.@{ant-prefix}-col@{class}-@{index}';
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) {
    // general
    @item: ~'.@{ant-prefix}-col@{class}-@{index}';
    .col((@index + 1), ~'@{list}, @{item}');
  }
  .col(@index, @list) when (@index > @grid-columns) {
    // terminal
    @{list} {
      flex: 0 0 auto;
      float: left;
    }
  }
  .col(1); // kickstart it
}

.loop-grid-columns(@index, @class) when (@index > 0) {
  .@{ant-prefix}-col@{class}-@{index} {
    display: block;
    box-sizing: border-box;
    width: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-push-@{index} {
    left: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-pull-@{index} {
    right: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-offset-@{index} {
    margin-left: percentage((@index / @grid-columns));
  }
  .@{ant-prefix}-col@{class}-order-@{index} {
    order: @index;
  }
  .loop-grid-columns((@index - 1), @class);
}

.loop-grid-columns(@index, @class) when (@index = 0) {
  .@{ant-prefix}-col@{class}-@{index} {
    display: none;
  }
  .@{ant-prefix}-col-push-@{index} {
    left: auto;
  }
  .@{ant-prefix}-col-pull-@{index} {
    right: auto;
  }
  .@{ant-prefix}-col@{class}-push-@{index} {
    left: auto;
  }
  .@{ant-prefix}-col@{class}-pull-@{index} {
    right: auto;
  }
  .@{ant-prefix}-col@{class}-offset-@{index} {
    margin-left: 0;
  }
  .@{ant-prefix}-col@{class}-order-@{index} {
    order: 0;
  }
}

.make-grid(@class: ~'') {
  .float-grid-columns(@class);
  .loop-grid-columns(@grid-columns, @class);
}
