.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0 0 0 / 40%);
  text-align: center;
  z-index: 10;
}

.overlay-inner {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  padding: 12px 48px;
  border-radius: 0px;
  margin-top: 20%;
  color: rgba(255 255 255 / 50%);
  font-size: 2em;
}

.processing-message {
  position: static;
}

.spinner {
  color: rgba(255 255 255 / 50%);
}
